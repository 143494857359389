import useSessionStore from '~/store/session'

export default defineNuxtPlugin(() => ({
    provide: {
        deleteRequest: (url: string) => {
            const accessToken = useSessionStore().getToken

            return fetch(url, {
                method: 'DELETE',
                body: 'Not empty body for safari',
                headers: {
                    authorization: accessToken || ''
                }
            })
        }
    }
}))
