import {
    FilterType,
    type CheckboxFilter,
    type FilterSection
} from '~/interfaces/filters'

export const hasFilter = (
    engagementFilters: FilterSection[],
    type: string
): boolean =>
    engagementFilters.filter((filterSection) =>
        filterSection.filters.some(
            (filter) =>
                (filter.type === FilterType.CHECKBOX ||
                    filter.type === FilterType.RADIO) &&
                filter.checked &&
                filter.value === type
        )
    ).length > 0

export const changeFilterStatus = (
    filterSections: FilterSection[],
    filterValue: string,
    checked: boolean
) => {
    const sections = filterSections.find((section) =>
        section.filters.some((filter) => filter.value === filterValue)
    )

    let filter: CheckboxFilter | undefined

    if (sections) {
        filter = sections.filters.find(
            (f) => f.value === filterValue
        ) as CheckboxFilter

        if (filterSections) {
            filter.checked = checked
        }
    } else {
        throw new Error('Filter not found')
    }
}
