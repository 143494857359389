import qs from 'qs'
import { keysToCamel, keysToSnake } from '~/plugins/json/utils'
import useTokenStore from '~/store/token'

export async function $fetchApi<T = unknown>(url: string, options?: any) {
    const snakeCaseParams = keysToSnake(options?.params)

    const formattedParams = qs.stringify(snakeCaseParams, {
        arrayFormat: 'brackets'
    })
    let builtUrl = url

    if (formattedParams) {
        builtUrl += `?${formattedParams}`
    }

    return $fetch<T>(builtUrl, {
        ...options,
        params: undefined,
        headers: {
            Authorization: useTokenStore().token || '',
            ...options?.headers
        },
        parseResponse: (response: string): T | undefined => {
            if (response) {
                return keysToCamel(JSON.parse(response))
            }

            return undefined
        }
    })
}

export function $fetchApiRaw<T = unknown>(url: string, options?: any) {
    const snakeCaseParams = keysToSnake(options?.params)

    const formattedParams = qs.stringify(snakeCaseParams, {
        arrayFormat: 'brackets'
    })

    let builtUrl = url

    if (formattedParams) {
        builtUrl += `?${formattedParams}`
    }

    return $fetch.raw<T>(builtUrl, {
        ...options,
        params: undefined,
        headers: {
            Authorization: useTokenStore().token || '',
            ...options?.headers
        },
        parseResponse: (response: string): T | undefined => {
            if (response) {
                return keysToCamel(JSON.parse(response))
            }

            return undefined
        }
    })
}
