export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.directive('click-outside', {
        beforeMount: (el, binding) => {
            el.clickOutsideEvent = (event: Event) => {
                event.stopPropagation()

                if (!(el === event.target || el.contains(event.target))) {
                    binding.value()
                }
            }
            document.addEventListener('click', el.clickOutsideEvent)
        },
        unmounted: (el) => {
            document.removeEventListener('click', el.clickOutsideEvent)
        }
    })
})
