import { DateTime } from 'luxon'
import { PeriodDashboard } from '~/interfaces/dashboard'
import type { PeriodObjectString } from '~/interfaces/newDashboard'
import { computed } from 'vue'

const usePeriodStore = defineStore('period', () => {
    const dateFrom = ref<DateTime>(
        DateTime.now().minus({ days: 90 }).startOf('hour')
    )

    const dateTo = ref<DateTime>(
        DateTime.now().startOf('hour').plus({ hours: 1 })
    )
    const periodEnumValue = ref<PeriodDashboard>(PeriodDashboard.LAST_90_DAYS)

    const getCurrentPeriod = computed<PeriodObjectString>(() => ({
        dateFrom: dateFrom.value.toString(),
        dateTo: dateTo.value.toString()
    }))

    const getPreviousPeriod = computed<PeriodObjectString>(() => {
        const currentPeriodDuration = dateTo.value.diff(
            dateFrom.value,
            'days'
        ).days

        return {
            dateFrom: dateFrom.value
                .minus({ days: currentPeriodDuration })
                .toString(),
            dateTo: dateFrom.value.toString()
        }
    })

    const setPeriodByDate = (
        startDate: DateTime,
        endDate: DateTime | undefined = undefined
    ) => {
        dateFrom.value = startDate.startOf('hour')
        dateTo.value =
            endDate || DateTime.now().startOf('hour').plus({ hours: 1 })
    }

    const setPeriodByPeriod = (periodValue: PeriodDashboard) => {
        periodEnumValue.value = periodValue
        const currentDate = DateTime.now().startOf('hour')
        switch (periodValue) {
            case PeriodDashboard.LAST_7_DAYS:
                setPeriodByDate(currentDate.minus({ days: 7 }))
                break
            case PeriodDashboard.LAST_30_DAYS:
                setPeriodByDate(currentDate.minus({ days: 30 }))
                break
            case PeriodDashboard.LAST_90_DAYS:
                setPeriodByDate(currentDate.minus({ days: 90 }))
                break
            case PeriodDashboard.CURRENT_MONTH:
                setPeriodByDate(currentDate.startOf('month').startOf('day'))
                break
            default:
                setPeriodByDate(currentDate.minus({ days: 90 }))
        }
    }

    return {
        dateFrom,
        dateTo,
        setPeriodByDate,
        setPeriodByPeriod,
        periodEnumValue,
        getCurrentPeriod,
        getPreviousPeriod
    }
})

type PeriodStore = Omit<
    ReturnType<typeof usePeriodStore>,
    keyof ReturnType<typeof defineStore>
>

export default usePeriodStore

export type { PeriodStore }
