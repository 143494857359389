import { defineStore } from 'pinia'
import type { ListResponse } from '~/interfaces/DTO'
import type { CommunityNetwork, Network } from '~/interfaces/network'

const useNetworksStore = defineStore(
    'networks',
    () => {
        const loadingNetwork = ref(false)
        const currentCommunityNetwork = ref<CommunityNetwork | null>(null)
        const currentNetwork = ref<Network | null>(null)

        const fetchLinkedNetworks = (
            idPartner: string,
            keyword: string,
            idNetwork?: number
        ) => {
            const { $throwError } = useNuxtApp()

            loadingNetwork.value = true

            return $fetchApi<ListResponse<any>>(
                `/partners-api/partners/${idPartner}/community_networks`,
                {
                    params: {
                        keyword: keyword || null,
                        limit: 100,
                        network_id: idNetwork
                    }
                }
            )
                .then((response) => ({
                    items: response.items
                }))
                .catch((error: any) => {
                    $throwError(
                        error,
                        'cannot fetch networks linked to partner'
                    )
                })
                .finally(() => {
                    loadingNetwork.value = false
                })
        }

        const fetchCommunityNetworks = (name: string) => {
            const { $throwError } = useNuxtApp()

            loadingNetwork.value = true

            return $fetchApi<ListResponse<CommunityNetwork>>(
                '/partners-api/community_networks',
                {
                    params: {
                        name
                    }
                }
            )
                .then((response) => ({
                    items: response.items
                }))
                .catch((error: any) => {
                    $throwError(error, 'cannot fetch community networks')
                })
                .finally(() => {
                    loadingNetwork.value = false
                })
        }

        const fetchNetwork = (
            idPartner: string,
            idNetwork: number,
            networkType: string
        ) => {
            const { $throwError } = useNuxtApp()

            if (networkType === 'community') {
                loadingNetwork.value = true

                return $fetchApi<CommunityNetwork>(
                    `/partners-api/partners/${idPartner}/community_networks/${idNetwork}`
                )
                    .then((response) => {
                        setCurrentCommunityNetwork(response)
                    })
                    .catch((error: any) => {
                        $throwError(error, 'cannot fetch network')
                    })
                    .finally(() => {
                        loadingNetwork.value = false
                    })
            }

            if (networkType === 'interop') {
                return fetchLinkedNetworks(idPartner, '', idNetwork).then(
                    (res) => {
                        if (res?.items && res.items.length === 1) {
                            currentCommunityNetwork.value = res.items[0]
                            currentNetwork.value = res.items[0].networks[0]
                        } else {
                            $throwError(
                                null,
                                `cannot fetch network : ${JSON.stringify(res)}`
                            )
                        }
                    }
                )
            }

            $throwError(null, `wrong network type : ${networkType}`)

            return Promise.reject()
        }

        const linkNetwork = (idPartner: string, network: CommunityNetwork) => {
            loadingNetwork.value = true

            const payload = {
                id: network.id,
                networks: network.networks.map((net) => net.id)
            }

            return $fetchApi<any>(
                `/partners-api/partners/${idPartner}/community_networks`,
                { body: payload, method: 'POST' }
            )
                .then((response) => response)
                .catch((e: any) => {
                    throw e
                })
                .finally(() => {
                    loadingNetwork.value = false
                })
        }

        const setCurrentCommunityNetwork = (
            network: CommunityNetwork | null
        ) => {
            currentNetwork.value = null
            currentCommunityNetwork.value = network
        }

        const unlinkNetwork = (idPartner: string, networkId: number) => {
            const { $throwError } = useNuxtApp()

            return $fetchApi(
                `/partners-api/partners/${idPartner}/networks/${networkId}`,
                {
                    method: 'PUT'
                }
            )
                .then((response) => response)
                .catch((error: any) => {
                    $throwError(error, 'cannot unlink network')
                })
        }

        return {
            loadingNetwork,
            currentCommunityNetwork,
            currentNetwork,
            fetchLinkedNetworks,
            fetchCommunityNetworks,
            fetchNetwork,
            linkNetwork,
            setCurrentCommunityNetwork,
            unlinkNetwork
        }
    },
    {
        persist: {
            storage: localStorage
        }
    }
)

type NetworksStore = Omit<
    ReturnType<typeof useNetworksStore>,
    keyof ReturnType<typeof defineStore>
>

export default useNetworksStore

export type { NetworksStore }
