import { defineStore } from 'pinia'

const useTokenStore = defineStore(
    'token',
    () => {
        const token = ref()

        return {
            token
        }
    },
    { persist: true }
)

type TokenStore = Omit<
    ReturnType<typeof useTokenStore>,
    keyof ReturnType<typeof defineStore>
>

export default useTokenStore

export type { TokenStore }
