import axios from 'axios'
import useSessionStore from '@/store/session'

export default defineNuxtPlugin(() => {
    const authStore = useSessionStore()
    let accessToken = authStore.getToken

    const $api = axios.create({
        headers: {
            Authorization: accessToken,
            'X-Frame-Options': 'SAMEORIGIN'
        }
    })

    $api.interceptors.request.use((config) => {
        accessToken = authStore.getToken
        config.headers.Authorization = accessToken

        return config
    })

    // Response interceptor for API calls
    $api.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config

            if (error.response?.status === 403 && !originalRequest._retry) {
                originalRequest._retry = true
                axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`

                return $api(originalRequest)
            }

            return Promise.reject(error)
        }
    )

    return {
        provide: {
            api: $api
        }
    }
})
