import { FilterType, type FilterSection } from '~/interfaces/filters'

export default (
    filtersSections: FilterSection[]
): Record<string, string[] | number> => {
    let queryObject: Record<string, string[] | number> = {}

    filtersSections.forEach((section) => {
        const sectionCheckboxValues: string[] = []

        section.filters.forEach((filter) => {
            if (
                filter.type === FilterType.RANGE &&
                (filter.minValue !== filter.min ||
                    filter.maxValue !== filter.max)
            ) {
                queryObject = {
                    ...queryObject,
                    [`${filter.value}Min`]: filter.minValue, // We add Min and Max to the key for the backend services
                    [`${filter.value}Max`]: filter.maxValue
                }
            } else if (
                (filter.type === FilterType.CHECKBOX ||
                    filter.type === FilterType.RADIO) &&
                filter.checked
            ) {
                sectionCheckboxValues.push(filter.value)
            }
        })

        if (sectionCheckboxValues.length) {
            queryObject = {
                ...queryObject,
                [section.value]: sectionCheckboxValues
            }
        }
    })

    return queryObject
}
