export enum PeriodDashboard {
    LAST_7_DAYS = 'LAST_7_DAYS',
    LAST_30_DAYS = 'LAST_30_DAYS',
    LAST_90_DAYS = 'LAST_90_DAYS',
    CURRENT_MONTH = 'CURRENT_MONTH'
}

export interface ValueTrendData {
    value: number | null
    trend: number | null
}

export interface CountTrendData {
    count: number | null
    trend: number | null
}
export interface RatingData {
    total?: ValueTrendData
    material?: ValueTrendData
    price?: ValueTrendData
    location?: ValueTrendData
    security?: ValueTrendData
}

export interface EngagementData {
    total?: CountTrendData
    ratings?: CountTrendData
    comments?: CountTrendData
    reports?: CountTrendData
}

export interface ChargesData {
    revenue: ValueTrendData
    number: CountTrendData
    consumption: ValueTrendData
}

export interface DashboardData {
    rating: RatingData
    engagements: EngagementData
    charges: ChargesData
}
