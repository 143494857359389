import type { Pages } from './pagination'

export enum FilterType {
    RANGE = 'RANGE',
    CHECKBOX = 'CHECKBOX',
    RADIO = 'RADIO'
}

export interface Filter {
    type: FilterType
    name?: string
    value: string
}

export interface RangeFilter extends Filter {
    type: FilterType.RANGE
    minValue: number
    maxValue: number
    min: number
    max: number
}

export interface CheckboxFilter extends Filter {
    type: FilterType.CHECKBOX
    checked: boolean
}

export interface RadioFilter extends Filter {
    type: FilterType.RADIO
    checked: boolean
}

export interface FilterSection {
    title?: string
    value: string
    filters: Array<RangeFilter | CheckboxFilter | RadioFilter>
    showMore?: boolean
}

export interface Filters {
    keyword: string
    orderColumn: string
    orderDirection: string
    pages: Pages
}

export interface FiltersStation extends Filters {
    stationFilters: FilterSection[]
    hideEmpty: boolean
}

export interface FiltersEngagement extends Filters {
    engagementFilters: FilterSection[]
}
